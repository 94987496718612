<template>
  <div v-show="loading" :style="containerStyle" ref="LoadingMask" class="loading-mask flex">
    <div :style="backdropStyle" class="loading-mask-backdrop"></div>
    <div class="loading-mask-isotype text-center">
      <template v-if="$props.showIsotype">
        <object
          v-if="$store.state.Global.online"
          :width="$props.sizeIsotype + '%'"
          :height="$props.sizeIsotype + '%'"
          :data="isotype"
          ref="Isotype"
          type="image/svg+xml"
        ></object>
        <img
          v-else
          ref="IsotypePng"
          src="/img/logotype/onca-vega-isotipo-240.png"
          alt="Onca Vega Logo"
          :width="$props.sizeIsotype + '%'"
          :height="$props.sizeIsotype + '%'"
        />
      </template>
    </div>
    <div
      v-if="$props.showProgressBar"
      :style="progressBarBackground"
      class="loading-mask-progress"
    ></div>
  </div>
</template>

<script>
import isotype from "@/assets/images/isotype/onca-vega-isotipo-240.svg";

export default {
  name: "LoadingMask",
  props: {
    /*
     * @property - sizeIsotype
     * Sets isotype width and height
     */
    sizeIsotype: {
      type: Number,
      default: 100
    },
    /*
     * @property - loopIsotype
     * Isotype animation is looped or not
     */
    loopIsotype: {
      type: Boolean,
      default: true
    },
    /*
     * @property - showIsotype
     * Defines if isotype should be hidden or visible
     */
    showIsotype: {
      type: Boolean,
      default: true
    },
    /*
     * @property - colorProgressBar
     * Defines progress bar color
     */
    colorProgressBar: {
      type: String,
      default() {
        return this.$utils.styles.colorsPalette.mainGreen;
      }
    },
    /*
     * @property - showProgressBar
     * Defines if progress bar should be hidden or visible
     */
    showProgressBar: {
      type: Boolean,
      default: true
    },
    /*
     * @property - backgroundColor
     * Defines background color
     */
    backgroundColor: {
      type: String,
      default() {
        return this.$utils.styles.colorsPalette.grayBlue;
      }
    },
    /*
     * @property - backgroundOpacity
     * Defines background opacity
     */
    backgroundOpacity: {
      type: Number,
      default: 1
    },
    /*
     * @property - isAppMask
     * Defines if mask is fixed, since is the application mask, otherwise it is absolute to fit the parent size
     */
    isAppMask: {
      type: Boolean,
      default: true
    },
    /*
     * @property - percentage
     * Defines percentage of progress bar
     */
    percentage: {
      type: Number,
      default: 0
    },
    /*
     * @property - animationStep
     * Duration step for animations
     */
    animationStep: {
      type: Number,
      default: 1500
    }
  },
  data() {
    return {
      isotype,
      isotypeLoaded: false,
      loading: true
    };
  },
  computed: {
    /*
     * @computed - containerStyle
     * Sets container style depending on the properties
     */
    containerStyle() {
      return { position: this.$props.isAppMask ? "fixed" : "absolute" };
    },
    /*
     * @computed - backdropStyle
     * Sets backdrop style depending on the properties
     */
    backdropStyle() {
      return {
        "background-color": `${this.$props.backgroundColor}`,
        "border-radius": this.$props.isAppMask ? "0" : "0.5rem",
        opacity: this.$props.backgroundOpacity
      };
    },
    /*
     * @computed - progressBarBackground
     * Sets grandient background for progress bar
     */
    progressBarBackground() {
      const colorBlurness = this.$props.percentage - (100 - this.$props.percentage) / 5;

      return `background-image: linear-gradient(to right, ${this.$props.colorProgressBar} ${colorBlurness}%, transparent ${this.$props.percentage}%)`;
    }
  },
  watch: {
    /*
     * @watcher - $props.percentage
     * If current value is 100, will fade LoadingMask
     */
    async ["$props.percentage"](value) {
      if (value === 100) {
        await this.m_animateFade(this.$refs.LoadingMask, this.$props.animationStep / 2, "out");
        this.clearLoadingMask();
      } else if (value < 100) {
        this.loading = true;
        this.m_animateFade(this.$refs.LoadingMask, this.$props.animationStep / 5, "in");
        this.isotypeListener();
      }
    }
  },
  methods: {
    /*
     * @function - animateIsotypePng
     * Calls isotype png animation
     * @param - none
     */
    animateIsotypePng() {
      const { IsotypePng } = this.$refs;

      this.m_animateFade(IsotypePng, 575, "in")
        .then(() => this.m_animateFade(IsotypePng, 575))
        .then(() => this.m_animateFade(IsotypePng, 575, "in"));
    },
    /*
     * @function - isotypeListener
     * Adds or removes event listener for isotype svg image animation
     * @param - state (String): if true, listener will be added, otherwise will be removed
     */
    isotypeListener(state = true) {
      if (!this.$props.showIsotype) {
        return;
      }

      const { Isotype } = this.$refs;

      if (Isotype) {
        if (!state) {
          Isotype.removeEventListener("load", this.animateIsotype);
        } else if (!this.isotypeLoaded) {
          this.isotypeLoaded = true;
          Isotype.addEventListener("load", this.animateIsotype);
        } else {
          this.animateIsotype();
        }
      }
    },
    /*
     * @function - animateIsotype
     * Calls isotype animation
     * @param - none
     */
    animateIsotype() {
      this.m_animateIsotype(
        this.$refs.Isotype.contentDocument,
        this.$props.animationStep,
        this.$props.loopIsotype
      );
    },
    /*
     * @function - clearLoadingMask
     * Clears loading mask after everything is loaded
     * @param - emit (Boolean): If true, emits loaded to parent, otherwise avoid doing it
     */
    clearLoadingMask(emit = true) {
      if (emit) {
        this.$emit("loaded");
      }
      this.isotypeListener(false);
      this.m_removeImagotypeAnimation();
      this.loading = false;
    }
  },
  created() {
    if (this.$props.percentage === 100) {
      this.loading = false;
    }
  },
  mounted() {
    if (this.loading) {
      this.$store.state.Global.online ? this.isotypeListener() : this.animateIsotypePng();
    }
  },
  beforeDestroy() {
    this.clearLoadingMask(false);
  }
};
</script>

<style lang="scss" scoped>
.loading-mask {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;

  &-backdrop {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  &-isotype {
    margin: auto;

    img {
      opacity: 0;
    }
  }

  &-progress {
    bottom: 0;
    height: 1rem;
    width: 100%;
    position: absolute;

    @include breakpoint($small) {
      height: 2.1875rem;
    }

    @include breakpoint($medium) {
      height: 2.8125rem;
    }
  }
}
</style>
